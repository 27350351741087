import { Avatar, Box, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useUserQuery } from '../../src/generated/graphql';

type UserProps = {};

export const User: React.FC<UserProps> = () => {
  const [authToken, setAuthToken] = useState('');

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    setAuthToken(authToken || '');
  }, []);

  const userQuery = useUserQuery({
    variables: {
      authToken,
    },
  });

  useEffect(() => {
    if (userQuery.loading) return;

    if (userQuery.data?.user === null) {
      location.href = '/signin';
    }
  }, [userQuery.data]);

  return (
    <>
      {userQuery.loading ? (
        <Spinner />
      ) : (
        <HStack>
          <Avatar size={'sm'} name={userQuery.data?.user?.name || ''} />
          <VStack
            display={{ base: 'none', md: 'flex' }}
            alignItems='flex-start'
            spacing='1px'
            ml='2'
          >
            <Text fontSize='sm'>{userQuery.data?.user?.name}</Text>
            <Text fontSize='xs' color='gray.600'>
              {userQuery.data?.user?.role}
            </Text>
          </VStack>
          <Box display={{ base: 'none', md: 'flex' }}>
            {/* <FaAngleDown /> */}
          </Box>
        </HStack>
      )}
    </>
  );
};

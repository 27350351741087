import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Logo } from './Logo';

export const Footer: React.FC = () => {
  const { t } = useTranslation(['common']);

  const router = useRouter();

  const companySlug = router.query.companySlug as string;

  return (
    <Box
      px={{ base: 4, md: 4 }}
      py={4}
      ml={{ base: 0, md: 60 }}
      bg='white'
      borderTopWidth='1px'
      borderTopColor='gray.200'
    >
      <HStack gap={4}>
        <Logo variant='grey' size={0.7} />

        <Flex gap={4} flexWrap='wrap'>
          <Link href={`/${companySlug}/agb`} passHref>
            <Text color='grey'>{t('agb')}</Text>
          </Link>

          <Link href={`/${companySlug}/privacy`} passHref>
            <Text color='grey'>{t('privacy')}</Text>
          </Link>

          <Link href={`/${companySlug}/imprint`} passHref>
            <Text color='grey'>{t('imprint')}</Text>
          </Link>

          <a
            href={`mailto:${t('supportEmail')}`}
            target='_blank'
            rel='noReferrer'
          >
            <Text color='grey'>{t('supportEmail')}</Text>
          </a>
        </Flex>
      </HStack>
    </Box>
  );
};

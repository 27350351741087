// Copied from https://chakra-templates.dev/navigation/sidebar

import React, { ReactNode, useContext } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  Spinner,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  BiBell,
  BiBuilding,
  BiBuildingHouse,
  BiCategory,
  BiCoffeeTogo,
  BiFoodMenu,
  BiLinkExternal,
  BiMenu,
} from 'react-icons/bi';
import { MdOutlineFastfood } from 'react-icons/md';
import { BsTags, BsCartCheck } from 'react-icons/bs';
import { IconType } from 'react-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  useCompanyQuery,
  useLocationQuery,
  useTablesQuery,
} from '../../src/generated/graphql';
import { useTranslation } from 'next-i18next';
import { User } from '../components/User';
import { Logo } from '../components/Logo';
import { Footer } from '../components/Footer';
import { locationPermissionsContext } from '../contexts/LocationPermissionsContext';

const GUEST_APP_URL = process.env.NEXT_PUBLIC_GUEST_APP_URL;

type SidebarWithHeaderProps = {
  children: ReactNode;
  isCompany?: boolean;
};

export const SidebarWithHeader: React.FC<SidebarWithHeaderProps> = ({
  isCompany,
  children,
}) => {
  const { t } = useTranslation(['common']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const router = useRouter();
  const locationSlug = router.query.locationSlug as string;

  return (
    <Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        isCompany={isCompany}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} isCompany={isCompany} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Grid gridTemplateRows='auto 1fr auto' minHeight='100vh'>
        <MobileNav onOpen={onOpen} />
        <GridItem minWidth={0}>
          <Box ml={{ base: 0, md: 60 }} p='4'>
            <>{children}</>
          </Box>
        </GridItem>
        <GridItem>
          <Footer />
        </GridItem>
      </Grid>
    </Box>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
  isCompany?: boolean;
}

const SidebarContent = ({ onClose, isCompany, ...rest }: SidebarProps) => {
  const { t } = useTranslation(['common']);

  const router = useRouter();
  const companySlug = router.query.companySlug as string;
  const locationSlug = router.query.locationSlug as string;

  const companyQuery = useCompanyQuery({
    variables: {
      slug: companySlug,
    },
  });

  const locationQuery = useLocationQuery({
    variables: {
      slug: locationSlug || '',
    },
  });

  const isLocationFound = locationQuery.data?.location;

  const { canCallWaiter, canOrder } = useContext(locationPermissionsContext);
  const canOrderOrHasWaiterCall = canOrder || canCallWaiter;
  const hasOnlyWaiterCalls = !canOrder && canCallWaiter;

  const dashboardTitle = hasOnlyWaiterCalls
    ? t('orders.titleWaiterCalls')
    : t('orders.titleOrders');

  const dashBoardIcon = hasOnlyWaiterCalls ? BiBell : BsCartCheck;

  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <HStack
        alignItems='center'
        justifyContent={['space-between', 'space-between', 'center']}
      >
        <Box p={4}>
          <Link href={`/${companySlug}/`}>
            <Logo size={1} />
          </Link>
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </HStack>

      {companyQuery.loading ? (
        <Spinner />
      ) : (
        <>
          {companyQuery.data?.company ? (
            <>
              {isCompany ? (
                <NavItem icon={BiBuildingHouse} path='locations'>
                  {t('locations.title')}
                </NavItem>
              ) : isLocationFound ? (
                <VStack gap={2} alignItems='initial'>
                  {canOrderOrHasWaiterCall && (
                    <>
                      <SubNavItem icon={dashBoardIcon} path='orders'>
                        {dashboardTitle}
                      </SubNavItem>
                      <Divider />
                    </>
                  )}
                  <SubNavItem icon={BiFoodMenu} path='menuCategories'>
                    {t('menuCategories.title')}
                  </SubNavItem>
                  <SubNavItem icon={MdOutlineFastfood} path='menus'>
                    {t('menus.title')}
                  </SubNavItem>
                  <SubNavItem icon={BiCategory} path='itemCategories'>
                    {t('itemCategories.title')}
                  </SubNavItem>
                  <SubNavItem icon={BiCoffeeTogo} path='items'>
                    {t('items.title')}
                  </SubNavItem>
                  <SubNavItem icon={BsTags} path='tagCategories'>
                    {t('tagCategories.title')}
                  </SubNavItem>
                </VStack>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: string;
  path: string;
}
const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
  const router = useRouter();
  const companySlug = router.query.companySlug;

  const activePage = router.pathname.split('/')[2];

  return (
    <Link href={`/${companySlug}/${path}`}>
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='3xl'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'yellow.400',
        }}
        bg={activePage === path ? 'yellow.300' : 'transparent'}
        {...rest}
      >
        {icon && <Icon mr='4' fontSize='16' as={icon} />}
        {children}
      </Flex>
    </Link>
  );
};

interface SubNavItemProps extends FlexProps {
  icon: IconType;
  children: string;
  path: string;
}

const SubNavItem = ({ icon, path, children, ...rest }: SubNavItemProps) => {
  const router = useRouter();
  const companySlug = router.query.companySlug;
  const locationSlug = router.query.locationSlug;

  const activePage = router.pathname.split('/')[4];

  return (
    <Link href={`/${companySlug}/locations/${locationSlug}/${path}`}>
      <Flex
        align='center'
        px='4'
        py='2'
        mx='4'
        borderRadius='3xl'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'yellow.400',
        }}
        bg={activePage === path ? 'yellow.300' : 'transparent'}
        {...rest}
      >
        {icon && <Icon mr='4' fontSize='16' as={icon} />}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { t } = useTranslation(['common']);

  const router = useRouter();
  const companySlug = (router.query.companySlug as string) || '';
  const locationSlug = (router.query.locationSlug as string) || '';

  const companyQuery = useCompanyQuery({
    variables: {
      slug: companySlug,
    },
  });

  const locationQuery = useLocationQuery({
    variables: {
      slug: locationSlug || '',
    },
  });

  const isLocationFound = locationQuery.data?.location;

  const tableQuery = useTablesQuery({
    variables: {
      locationSlug,
    },
  });

  const tableNumber = tableQuery.data?.tables?.[0]?.number;

  const handleSignout = () => {
    localStorage.removeItem('authToken');
    router.push('/');
  };
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between' }}
      position='sticky'
      top={0}
      zIndex={1}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<BiMenu />}
      />

      <HStack>
        {isLocationFound && (
          <Link href={`/${companySlug}/locations/${locationSlug}`} passHref>
            <HStack>
              <BiBuildingHouse />
              <Text
                display={{ base: 'flex' }}
                fontSize='l'
                borderColor='grey.2400'
              >
                {locationQuery.data?.location?.name}
              </Text>
            </HStack>
          </Link>
        )}
      </HStack>

      <HStack>
        {tableNumber && (
          <Link
            href={`${GUEST_APP_URL}/${locationSlug}/${tableNumber}`}
            passHref
            target='_blank'
          >
            <HStack>
              <BiLinkExternal />
              <Text
                display={{ base: 'flex' }}
                fontSize='l'
                borderColor='grey.2400'
              >
                {t('navigation.goToMenuCard')}
              </Text>
            </HStack>
          </Link>
        )}
      </HStack>

      <HStack spacing={{ base: '0', md: '6' }} justifySelf='flex-end'>
        {companySlug && (
          <Link href={`/${companySlug}/locations`} passHref>
            <HStack>
              <BiBuilding />
              <Text
                display={{ base: 'flex' }}
                fontSize='l'
                borderColor='grey.2400'
              >
                {companyQuery.data?.company?.name}
              </Text>
            </HStack>
          </Link>
        )}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition='all 0.3s'
              _focus={{ boxShadow: 'none' }}
            >
              <User />
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={handleSignout}>{t('signout.title')}</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

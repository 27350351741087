import { Button, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCompanyQuery } from '../../src/generated/graphql';

type WithCompanyProps = {
  children: React.ReactNode[] | React.ReactNode;
};

export const WithCompany: React.FC<WithCompanyProps> = ({ children }) => {
  const router = useRouter();
  const companySlug = router.query.companySlug as string;
  const { t } = useTranslation(['common']);

  const companyQuery = useCompanyQuery({
    variables: {
      slug: companySlug,
    },
  });
  return (
    <>
      {companyQuery.loading ? (
        <HStack h='100vh' justifyContent='center'>
          <Spinner />
        </HStack>
      ) : (
        <>
          {companyQuery.data?.company ? (
            <>{children}</>
          ) : (
            <VStack alignItems='initial' p={8}>
              <Text>{t('company.noResults')}</Text>
              <Link href='/signin' passHref>
                <Button variant='solid' alignSelf='start'>
                  {t('signin.button')}
                </Button>
              </Link>
            </VStack>
          )}
        </>
      )}
    </>
  );
};
